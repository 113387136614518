import { combineReducers } from 'redux';
import userSilcer from './slices/UserSilcer';
import commonSlicer from './slices/commonSlicer';

const RootReducer = combineReducers({
  users: userSilcer,
  common: commonSlicer,
});

export default RootReducer;
