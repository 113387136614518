import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';

const SuspenseComponent = ({ children }) => {
  return (
    <React.Suspense
      fallback={
        <Box
          display="flex"
          flexGrow={1}
          width="100%"
          height="100vh"
          alignItems="center"
          flexDirection="row"
        >
          <Box
            alignItems="center"
            display="flex"
            marginRight="auto"
            marginLeft="auto"
          >
            <CircularProgress />
          </Box>
        </Box>
      }
    >
      {children}
    </React.Suspense>
  );
};
SuspenseComponent.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SuspenseComponent;
