// @ts-nocheck
import { useState } from 'react';
import axios from 'axios';
import API_ENDPOINTS from '../../const/ApiEndPoints';

export default function useEntity() {
  const [allModelList, setAllModelList] = useState([]);
  const getAllModelEntity = (body) => {
    return axios
      .get(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.MI.READ_ALL_MODEL_ENTITY}`,
        { params: body }
      )
      .then((res) => {
        setAllModelList(res?.data?.modelEntity);
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const getEntityStatuses = (body) => {
    return axios
      .get(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.MI.ENTITY_STATUSES}`,
        { params: body }
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const createModelFirstStep = (body) => {
    return axios
      .post(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.MI.CREATE_MODEL_ENTITY_FIRST_STEP}`,
        body
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const archiveEntity = (body) => {
    return axios
      .post(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.MI.ARCHIVE_ENTITY}`,
        body
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const createModelSecondStep = (body) => {
    return axios
      .post(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.MI.CREATE_MODEL_ENTITY_SECOND_STEP}`,
        body
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const createModelThirdStep = (body) => {
    return axios
      .post(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.MI.CREATE_MODEL_ENTITY_THIRD_STEP}`,
        body
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const createModelForthStep = (body) => {
    return axios
      .post(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.MI.CREATE_MODEL_ENTITY_FORTH_STEP}`,
        body
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const deleteModelEntity = (body) => {
    return axios
      .delete(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.MI.DELETE_ENTITY}`,
        { params: body }
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const getSingleEntityDetails = (body) => {
    return axios
      .get(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.MI.GET_SINGLE_ENTITY}`,
        { params: body }
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const updateModelEntity = (body) => {
    return axios
      .post(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.MI.UPDATE_MODEL}`,
        body
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const isEntityAvailable = (params) => {
    return axios
      .get(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.MI.MODEL_AVAILABLE}`,

        { params }
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const integrateEntity = (body) => {
    return axios
      .post(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.NUI.INTEGRATE_ENTITY}`,
        body
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };

  const getSearchList = (body) => {
    return axios
      .post(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.DB.FULL_SEARCH}`,
        body
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };

  const downloadEntitiesReport = (body) => {
    return axios
      .get(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.MI.DOWNLOAD_REPORT_ENTITIES}`,
        {
          params: body, // Place params here in the config object
          responseType: 'arraybuffer', // Set responseType to 'arraybuffer' to handle binary data
        }
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };

  return {
    getAllModelEntity,
    allModelList,
    setAllModelList,
    createModelThirdStep,
    createModelForthStep,
    createModelSecondStep,
    createModelFirstStep,
    deleteModelEntity,
    getSingleEntityDetails,
    updateModelEntity,
    isEntityAvailable,
    integrateEntity,
    getSearchList,
    archiveEntity,
    downloadEntitiesReport,
    getEntityStatuses,
  };
}
