const configReactQueryBuilderTheme = {
  palette: {
    secondary: {
      // buttons inside reactquerybuilder are taking main as their bgcolor.
      main: '#0BB7A7',
      // buttons inside reactquerybuilder are taking dark as their hover state bgcolor.
      dark: '#0BB7A7',
      contrastText: '#FFFFFF',
    },
  },
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: {
          minWidth: '0 !important',
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard',
      },
      styleOverrides: {
        icon: {
          right: '0',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          height: '32px',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          '&:hover': {
            fontWeight: '500',
          },
        },
      },
    },
  },
};

export default configReactQueryBuilderTheme;
