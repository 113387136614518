/**
 * @ATTRIBUTE_LABEL : Regular expression to match a string that contains at least one alphabet while allowing special characters, numbers, text, and whitespace characters in any order.
 *
 * @VALID_OPTIONS_FIELD : This regular expression pattern (`VALID_OPTIONS_FIELD`) is designed to validate a string that contains a comma-separated list of values. It ensures that the string adheres to the following rules:

  - The string must start with at least one word character (e.g., an alphabet character).
  - It can then be followed by zero or more sequences of a comma (`,`), followed by one or more word characters (`\w`).
  - Each segment of the string is separated by a comma, but the string cannot end with a comma.

  ### Valid Examples

  - "a": A single valid value with no trailing comma.
  - "a,ab,abc,abcd": Multiple values separated by commas, with no trailing comma.
  - "xyz,123,pqr": Alphanumeric values separated by commas.
  - "abc ,b,c , d": Spaces around commas are allowed.

  ### Invalid Examples

  - "a,b,c,d,": Trailing comma at the end.
  - "a,": A single value with a trailing comma.
  - ", ": Contains only trailing comma and space with no values.


  Use this regular expression to validate strings that represent comma-separated lists of values in your application.


 * @VALID_ENTITY_ID : Regular expression pattern (`VALID_ENTITY_ID`) designed to validate an entity ID. It ensures that the entity ID adheres to the following rules:
 *
 *   - Starts with one or more letters.
 *   - Followed by a hyphen.
 *   - Ends with one or more digits (0-9).
 */

const REGEX = {
  NOT_ONLY_NUMBER: '.*?[^0-9].*',
  USERNAME: /^(?=.*[a-z0-9])[a-z0-9._%+\-@]{1,320}$/,
  SPECIAL_CHARACTERS_NOT_ALLOWED: /^[a-zA-Z0-9\s]*$/,
  CANNOT_START_WITH_NUMBERS: /^[^0-9\s]/,
  WHOLE_STRING_CANNOT_BE_SPECIAL_CHARACTERS: /^(?![\W_]*$)[\s\S]*$/,
  ONLY_ALPHABETS: /^[A-Za-z]+$/,
  ATTRIBUTE_LABEL:
    /^(?=.*[a-zA-Z])[a-zA-Z0-9\s!@#$%^&*()\-=_+[\]{}|;':",.<>/?]*$/,
  ATTRIBUTE_NAME: /^(?=.*[a-zA-Z])[a-zA-Z0-9\s\-_]*$/,
  VALID_OPTIONS_FIELD: /^[^,]+(?:,[^,]+)*$/,
  VALID_ENTITY_ID: /^[A-Za-z]+-\d+$/,
  VALID_ENTITY_CODE: /^[a-zA-Z0-9-]*$/, // all alphanumeric characters and hyphen
  URL_REGEX: /^(www|https?|HTTP)/i,
  HEX_REGEX: /^#([\da-f]{3}){1,2}$/i,
  RGB_REGEX:
    /^rgb\((((((((1?[1-9]?\d)|10\d|(2[0-4]\d)|25[0-5]),\s?)){2}|((((1?[1-9]?\d)|10\d|(2[0-4]\d)|25[0-5])\s)){2})((1?[1-9]?\d)|10\d|(2[0-4]\d)|25[0-5]))|((((([1-9]?\d(\.\d+)?)|100|(\.\d+))%,\s?){2}|((([1-9]?\d(\.\d+)?)|100|(\.\d+))%\s){2})(([1-9]?\d(\.\d+)?)|100|(\.\d+))%))\)$/i,
  RGBA_REGEX:
    /^rgba\((((((((1?[1-9]?\d)|10\d|(2[0-4]\d)|25[0-5]),\s?)){3})|(((([1-9]?\d(\.\d+)?)|100|(\.\d+))%,\s?){3}))|(((((1?[1-9]?\d)|10\d|(2[0-4]\d)|25[0-5])\s){3})|(((([1-9]?\d(\.\d+)?)|100|(\.\d+))%\s){3}))\/\s)((0?\.\d+)|[01]|(([1-9]?\d(\.\d+)?)|100|(\.\d+))%)\)$/i,
  HSL_REGEX:
    /^hsl\(((((([12]?[1-9]?\d)|[12]0\d|(3[0-5]\d))(\.\d+)?)|(\.\d+))(deg)?|(0|0?\.\d+)turn|(([0-6](\.\d+)?)|(\.\d+))rad)((,\s?(([1-9]?\d(\.\d+)?)|100|(\.\d+))%){2}|(\s(([1-9]?\d(\.\d+)?)|100|(\.\d+))%){2})\)$/i,
  HSLA_REGEX:
    /^hsla\(((((([12]?[1-9]?\d)|[12]0\d|(3[0-5]\d))(\.\d+)?)|(\.\d+))(deg)?|(0|0?\.\d+)turn|(([0-6](\.\d+)?)|(\.\d+))rad)(((,\s?(([1-9]?\d(\.\d+)?)|100|(\.\d+))%){2},\s?)|((\s(([1-9]?\d(\.\d+)?)|100|(\.\d+))%){2}\s\/\s))((0?\.\d+)|[01]|(([1-9]?\d(\.\d+)?)|100|(\.\d+))%)\)$/i,
  COLOR_REGEX: /^color\(([^()]+)\)$/,
  UPPERCASE: /[A-Z]/,
  LOWERCASE: /[a-z]/,
  NUMBER: /\d/,
  SPECIAL_CHAR: /[^a-zA-Z0-9]/,
};
export default REGEX;
