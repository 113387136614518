// @ts-nocheck
import { useState } from 'react';
import axios from 'axios';
import API_ENDPOINTS from '../../const/ApiEndPoints';

export default function useAssociation() {
  const [associationDetails, setAssociationDetails] = useState([]);
  const getAssociationDetails = (body) => {
    return axios
      .get(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.MI.ASSOCIATION_DETAILS}`,
        { params: body }
      )
      .then((res) => {
        setAssociationDetails(res?.data);
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const removeModelAssociation = (body) => {
    return axios
      .post(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.MI.REMOVE_MODEL_ASSOCIATION}`,
        body
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const getTemplateAssociations = (body) => {
    return axios
      .get(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.MI.GET_TEMPLATE_ASSOCIATIONS}`,
        {
          params: body,
        }
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const getParentAssociation = (body) => {
    return axios
      .get(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.MI.GET_PARENT_ASSOCIATION}`,
        {
          params: body,
        }
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };

  return {
    getAssociationDetails,
    setAssociationDetails,
    associationDetails,
    removeModelAssociation,
    getTemplateAssociations,
    getParentAssociation,
  };
}
