// @ts-nocheck
import React, { useCallback, useMemo, useState } from 'react';
import { Box, Stack, Breadcrumbs, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { map, startCase, isEmpty } from 'lodash';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import Diversity2Icon from '@mui/icons-material/Diversity2';
import VpnKeyIcon from '@mui/icons-material/VpnKey';

import { isValueEmpty } from '../../utils/Utils';
import { ReactComponent as ModelIcon } from '../../assets/images/ModelDetails/ModelLogo.svg';
import { ReactComponent as GitPullRequest } from '../../assets/images/ModelDetails/GitPullRequest.svg';
import REGEX from '../../const/Regex';
import useCommon from '../../hooks/useCommon';
import ConfirmModal from './Modals/ConfirmModal';

const LinkLabelComponent = ({
  setNavigateToRoute,
  handleModalOpen,
  to,
  value,
}) => {
  const handelNavigate = useCallback(() => {
    setNavigateToRoute(to);
    handleModalOpen();
  }, [handleModalOpen, setNavigateToRoute, to]);
  return (
    <Typography
      variant="body2"
      sx={{
        cursor: 'pointer',
      }}
      onClick={handelNavigate}
    >
      {value}
    </Typography>
  );
};
LinkLabelComponent.propTypes = {
  setNavigateToRoute: PropTypes.func.isRequired,
  handleModalOpen: PropTypes.func.isRequired,
  to: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

const Breadcrumb = ({
  state: breadCrumbData,
  tabName = '',
  handleBreadcrumbClick = () => {},
  isDashboard,
}) => {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [navigateToRoute, setNavigateToRoute] = useState(false);

  const { common, dispatch, setIsWorkflowCompiled } = useCommon();

  const navigate = useNavigate();

  /**
   * If workflow is not compile then show modal for confirmation
   *  @name handleModalOpen
   * @returns {void}
   */
  const handleModalOpen = useCallback(() => {
    setIsConfirmModalOpen(true);
  }, [setIsConfirmModalOpen]);

  /**
   * Closes the confirmation modal by setting the state value.
   * @function
   * @name closeConfirmModal
   * @returns {void}
   * @example closeConfirmModal()
   */
  const closeConfirmModal = useCallback(() => {
    setIsConfirmModalOpen(false);
  }, [setIsConfirmModalOpen]);

  /**
   * Confirms the action, if user have compiled the workflow or not.
   *  @name confirm
   * @returns {void}
   */
  const confirm = useCallback(() => {
    dispatch(setIsWorkflowCompiled(true));
    closeConfirmModal();
    setTimeout(() => {
      navigate(navigateToRoute);
    }, 500);
  }, [dispatch, setIsWorkflowCompiled, navigateToRoute]);

  const location = useLocation();
  const paths = location.pathname.split('/').slice(1);
  const allPathsNames = map(paths, (item) => {
    if (REGEX.VALID_ENTITY_ID.exec(item)) {
      return item;
    }
    return startCase(item);
  });
  const pathNames = allPathsNames.map((item) => {
    if (item === 'ModelAssociation') {
      return 'Model Artifacts';
    }
    if (item === 'ModelInventory') {
      return 'My Models';
    }
    if (item === 'Workflow') {
      return 'Workflow';
    }
    if (item === 'EditWorkflow') {
      return 'Edit Workflow';
    }
    return item;
  });
  const [searchParams] = useSearchParams();
  const templateName = searchParams.get('eid');
  const workFlowName = searchParams.get('workflowType');

  // This is to workflows compile warning feature
  const renderLinkTag = (to, value) => {
    if (to !== '/workflows/edit-workflow') {
      if (common?.isWorkflowCompiled) {
        return (
          <Link
            className="custom-link"
            tabIndex={0} // Make it focusable
            style={{ textDecoration: 'inherit' }}
            to={to}
            aria-label={`${value}`}
          >
            <Typography variant="body2">{value}</Typography>
          </Link>
        );
      }

      // If workflow is not compiled then we will be restricting the navigation to other links
      return (
        <LinkLabelComponent
          setNavigateToRoute={setNavigateToRoute}
          handleModalOpen={handleModalOpen}
          to={to}
          value={value}
        />
      );
    }
    return (
      <Link style={{ textDecoration: 'inherit' }} to={to}>
        <Typography variant="body2">{value}</Typography>
      </Link>
    );
  };

  const navigateToDashboard = useCallback(() => {
    setNavigateToRoute('/dashboard');
    handleModalOpen();
  }, [setNavigateToRoute, handleModalOpen]);

  const handleKeyDown = useCallback((event) => {
    // Handle "Enter" or "Space" key to activate the breadcrumb
    if (event.key === 'Enter' || event.key === ' ') {
      window.location.href = '/dashboard?value=0';
    }
  }, []);

  const renderDashboardLink = useMemo(() => {
    if (common?.isWorkflowCompiled) {
      return (
        <Link
          className="custom-link"
          style={{
            textDecoration: 'none',
            cursor: 'pointer',
          }}
          onKeyDown={handleKeyDown}
          underline="hover"
          key="1"
          aria-label="Dashboard"
          tabIndex={0} // Make it focusable
          color="inherit"
          to="/dashboard?value=0"
        >
          <Typography variant="body7">Dashboard</Typography>
        </Link>
      );
    }

    // If workflow is not compiled then we will be restricting the navigation to other links
    return (
      <Typography
        variant="body2"
        sx={{
          cursor: 'pointer',
        }}
        className="custom-link"
        aria-label="Dashboard"
        tabIndex={0} // Make it focusable
        onClick={navigateToDashboard}
      >
        Dashboard
      </Typography>
    );
  }, [common?.isWorkflowCompiled, handleModalOpen, setNavigateToRoute]);

  const renderIcon = (path) => {
    if (path?.type === 'ModelInventory') {
      return (
        <Box
          title="Model"
          component="span"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ModelIcon
            color="primary"
            style={{
              width: '18px',
              height: '18px',
            }}
          />
        </Box>
      );
    }

    if (path?.type === 'ModelRequest') {
      return (
        <Box
          title="Assessment"
          component="span"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <GitPullRequest
            color="primary"
            style={{
              width: '18px',
              height: '18px',
              color: 'green',
            }}
          />
        </Box>
      );
    }

    if (path?.type === 'UseCase') {
      return (
        <Box
          title="UseCase"
          component="span"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Diversity2Icon
            color="primary"
            style={{
              width: '18px',
              height: '18px',
              color: 'green',
            }}
          />
        </Box>
      );
    }
    if (path?.type === 'accessRequest') {
      return (
        <Box
          title="Access request"
          component="span"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <VpnKeyIcon
            color="primary"
            style={{
              width: '18px',
              height: '18px',
              paddingTop: '3px',
            }}
          />
        </Box>
      );
    }

    return (
      <Box title="Model Artifact" component="span">
        <PrecisionManufacturingIcon
          color="primary"
          style={{
            width: '18px',
            height: '18px',
            paddingTop: '3px',
          }}
        />
      </Box>
    );
  };

  const getEntityTypeLabel = (path) => {
    if (path?.label === 'ModelInventory') {
      return 'My Models';
    }
    if (path?.label === 'ModelAssociation') {
      return 'Model Artifacts';
    }
    if (path?.label === 'useCase') {
      return 'Use Case';
    }
    return path?.label;
  };

  const handleDashboardKeydown = useCallback(
    (event) => {
      if (event?.key === 'Enter' || event?.key === ' ') {
        handleBreadcrumbClick();
      }
    },
    [handleBreadcrumbClick]
  );

  return (
    <Box
      sx={{
        background: (_theme) => _theme.palette.secondary.light1,
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      pl={2}
    >
      <Stack
        spacing={2}
        height="100%"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          sx={{
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '& .MuiBreadcrumbs-ol': {
              height: '100%',
            },
            '& .MuiBreadcrumbs-li': {
              '& span': {
                paddingLeft: '8px',
                paddingRight: '8px',
              },

              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            },
          }}
        >
          {!pathNames?.includes('Dashboard') ? renderDashboardLink : null}
          {breadCrumbData?.length > 0
            ? breadCrumbData?.map((path, i) => {
                return i === Number(breadCrumbData?.length) - 1 ? (
                  <Box
                    sx={{
                      background: (_theme) =>
                        _theme?.palette.secondary.contrastText,
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    tabIndex={0}
                    aria-label={path?.label}
                  >
                    {!isEmpty(path?.type) ? renderIcon(path) : null}
                    <Typography key={path?.path} variant="body2">
                      {path?.label}
                    </Typography>
                  </Box>
                ) : (
                  <Box
                    key={path?.path}
                    sx={{ display: 'flex', justifyContent: 'center' }}
                  >
                    {!isEmpty(path?.type) ? renderIcon(path) : null}
                    <Link
                      style={{ textDecoration: 'inherit', cursor: 'pointer' }}
                      to={path?.path}
                      aria-label={getEntityTypeLabel(path)}
                      className="custom-link"
                      tabIndex={0}
                      state={breadCrumbData.slice(0, i + 1)}
                    >
                      <Typography variant="body7">
                        {getEntityTypeLabel(path)}
                      </Typography>
                    </Link>
                  </Box>
                );
              })
            : pathNames.map((value, index) => {
                const to = `/${paths.slice(0, index + 1).join('/')}`;
                return index === paths.length - 1 ? (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      ...((isValueEmpty(tabName) ||
                        isValueEmpty(templateName) ||
                        isValueEmpty(workFlowName)) && {
                        height: '100%',
                        paddingTop: '14px',
                        paddingBottom: '14px',
                      }),
                      background: (_theme) =>
                        !isValueEmpty(tabName) ||
                        !isValueEmpty(templateName) ||
                        !isValueEmpty(workFlowName)
                          ? _theme.palette.secondary.light1
                          : _theme?.palette.secondary.contrastText,
                    }}
                  >
                    {!isValueEmpty(breadCrumbData)
                      ? renderIcon(breadCrumbData)
                      : null}
                    {isDashboard ? (
                      <Typography
                        variant="body2"
                        className="custom-link"
                        tabIndex={0}
                        aria-label={value}
                        onKeyDown={handleDashboardKeydown}
                        onClick={handleBreadcrumbClick}
                      >
                        {value}
                      </Typography>
                    ) : (
                      <Typography
                        key={to}
                        variant="body2"
                        aria-label={breadCrumbData?.uniqueId || value}
                        tabIndex={0}
                        aria-current="page"
                      >
                        {breadCrumbData?.uniqueId || value}
                      </Typography>
                    )}
                  </Box>
                ) : (
                  <Box>{renderLinkTag(to, value)}</Box>
                );
              })}
          {workFlowName ? (
            <Typography
              variant="body2"
              sx={{
                background: (_theme) => _theme?.palette.secondary.contrastText,
                height: '100%',
                paddingTop: '14px',
                paddingBottom: '14px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              aria-label={workFlowName}
              tabIndex={0}
              aria-current="page"
            >
              {workFlowName}
            </Typography>
          ) : null}
          {templateName ? (
            <Typography
              variant="body2"
              sx={{
                background: (_theme) => _theme?.palette.secondary.contrastText,
                height: '100%',
                paddingTop: '14px',
                paddingBottom: '14px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              aria-label={templateName}
              tabIndex={0}
              aria-current="page"
            >
              {templateName}
            </Typography>
          ) : null}
          {tabName ? (
            <Typography
              variant="body2"
              sx={{
                background: (_theme) => _theme?.palette.secondary.contrastText,
                height: '100%',
                paddingTop: '14px',
                paddingBottom: '14px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              tabIndex={0}
              aria-label={tabName}
              aria-current="page"
            >
              {tabName}
            </Typography>
          ) : null}
        </Breadcrumbs>
      </Stack>
      <ConfirmModal
        open={isConfirmModalOpen}
        handleClose={closeConfirmModal}
        confirm={confirm}
        alertLabelText={
          <Typography>{`You have unsaved changes. Do you really want to leave this page
        without saving?`}</Typography>
        }
        confirmButtonLabel="Yes"
        cancelButtonLabel="No"
      />
    </Box>
  );
};
export default Breadcrumb;
Breadcrumb.propTypes = {
  state: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])).isRequired,
  tabName: PropTypes.string.isRequired,
  handleBreadcrumbClick: PropTypes.func.isRequired,
  isDashboard: PropTypes.bool.isRequired,
};
