// @ts-nocheck
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  dashboardSearch: '',
  dashboardActiveFilter: {},
  modelInventorySearch: '',
  archivedModelSearch: '',
  modelAssociationSearch: '',
  templateListSearch: '',
  appManagementListSearch: '',
  workFlowListSearch: '',
  rulesSearch: '',
  userListSearch: '',
  groupListSearch: '',
  documentInventorySearch: '',
  documentTemplateSearch: '',
  modelInventoryHiddenColumns: [],
  modelAssocationHiddenColumns: [],
  modelInventoryColumnOrder: [],
  modelAssociationColumnOrder: [],
  userRoleListSearch: '',
  issueTypeListSearch: '',
  summaryTabsListSearch: '',
  isWorkflowCompiled: true,
  userSetting: {},
  modelRequestSearch: '',
  accessRequestSearch: '',
  useCaseSearch: '',
  triggerThemeChange: false,
  triggerHeaderIconChange: false,
  triggerProductIconChange: false,
};
export const commonSlice = createSlice({
  initialState,
  name: 'common',
  reducers: {
    setDashboardSearch: (state, action) => {
      return {
        ...state,
        dashboardSearch: action.payload,
      };
    },
    setDashboardActiveFilter: (state, action) => {
      return {
        ...state,
        dashboardActiveFilter: action.payload,
      };
    },
    setModelInventorySearch: (state, action) => {
      return {
        ...state,
        modelInventorySearch: action.payload,
      };
    },
    setModelArchivedSearch: (state, action) => {
      return {
        ...state,
        archivedModelSearch: action.payload,
      };
    },
    setModelAssociationSearch: (state, action) => {
      return {
        ...state,
        modelAssociationSearch: action.payload,
      };
    },
    setTemplateListSearch: (state, action) => {
      return {
        ...state,
        templateListSearch: action.payload,
      };
    },
    setAppManagementSearch: (state, action) => {
      return {
        ...state,
        appManagementListSearch: action.payload,
      };
    },
    setIsWorkflowCompiled: (state, action) => {
      return {
        ...state,
        isWorkflowCompiled: action.payload,
      };
    },
    setWorkFlowListSearch: (state, action) => {
      return {
        ...state,
        workFlowListSearch: action.payload,
      };
    },
    setRulesSearch: (state, action) => {
      return {
        ...state,
        rulesSearch: action.payload,
      };
    },
    setUserListSearch: (state, action) => {
      return {
        ...state,
        userListSearch: action.payload,
      };
    },
    setGroupListSearch: (state, action) => {
      return {
        ...state,
        groupListSearch: action.payload,
      };
    },
    setDocumentInventorySearch: (state, action) => {
      return {
        ...state,
        documentInventorySearch: action.payload,
      };
    },
    setDocumentTemplateSearch: (state, action) => {
      return {
        ...state,
        documentTemplateSearch: action.payload,
      };
    },
    setModelInventoryHiddenColumns: (state, action) => {
      return {
        ...state,
        modelInventoryHiddenColumns: action.payload,
      };
    },
    setModelAssociationHiddenColumns: (state, action) => {
      return {
        ...state,
        modelAssocationHiddenColumns: action.payload,
      };
    },
    setModelInventoryColumnOrder: (state, action) => {
      return {
        ...state,
        modelInventoryColumnOrder: action.payload,
      };
    },
    setModelAssociationColumnOrder: (state, action) => {
      return {
        ...state,
        modelAssociationColumnOrder: action.payload,
      };
    },
    setUserRoleListSearch: (state, action) => {
      return {
        ...state,
        userRoleListSearch: action.payload,
      };
    },
    setIssueTypeListSearch: (state, action) => {
      return {
        ...state,
        issueTypeListSearch: action.payload,
      };
    },
    setSummaryTabsListSearch: (state, action) => {
      return {
        ...state,
        summaryTabsListSearch: action.payload,
      };
    },
    setUserSetting: (state, action) => {
      return { ...state, userSetting: action.payload };
    },
    setModelRequestSearch: (state, action) => {
      return {
        ...state,
        modelRequestSearch: action.payload,
      };
    },
    setAccessRequestSearch: (state, action) => {
      return {
        ...state,
        accessRequestSearch: action.payload,
      };
    },
    setUseCaseSearch: (state, action) => {
      return {
        ...state,
        useCaseSearch: action.payload,
      };
    },
    setTriggerThemeChange: (state, action) => {
      return {
        ...state,
        triggerThemeChange: action.payload,
      };
    },
    setTriggerProductIconChange: (state, action) => {
      return {
        ...state,
        triggerProductIconChange: action.payload,
      };
    },
    setTriggerHeaderIconChange: (state, action) => {
      return {
        ...state,
        triggerHeaderIconChange: action.payload,
      };
    },
  },
});
export const {
  setDashboardSearch,
  setDashboardActiveFilter,
  setModelInventorySearch,
  setModelArchivedSearch,
  setModelAssociationSearch,
  setTemplateListSearch,
  setAppManagementSearch,
  setWorkFlowListSearch,
  setRulesSearch,
  setUserListSearch,
  setGroupListSearch,
  setDocumentInventorySearch,
  setDocumentTemplateSearch,
  setModelInventoryHiddenColumns,
  setModelAssociationHiddenColumns,
  setModelInventoryColumnOrder,
  setModelAssociationColumnOrder,
  setUserRoleListSearch,
  setIssueTypeListSearch,
  setSummaryTabsListSearch,
  setUserSetting,
  setIsWorkflowCompiled,
  setModelRequestSearch,
  setAccessRequestSearch,
  setUseCaseSearch,
  setTriggerThemeChange,
  setTriggerProductIconChange,
  setTriggerHeaderIconChange,
} = commonSlice.actions;
export default commonSlice.reducer;
