// @ts-nocheck
import { useState } from 'react';
import axios from 'axios';
import API_ENDPOINTS from '../../const/ApiEndPoints';

export default function useDocument() {
  const [documentsList, setDocumentsList] = useState([]);
  const [documentTree, setDocumentTree] = useState([]);
  const fetchDocumentsList = (body) => {
    return axios
      .get(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.DOC.MODEL_DOCUMENTS}`,
        { params: body }
      )
      .then((res) => {
        setDocumentsList(res?.data);
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };

  const fetchDocumentDetails = (body) => {
    return axios
      .get(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.DOC.UPLOAD_DOCUMENTS}`,
        { params: body }
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };

  const getDocumentTree = (body) => {
    return axios
      .get(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.DOC.DOCUMENT_TREE}`,
        { params: body }
      )
      .then((res) => {
        setDocumentTree(res?.data);
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };

  const attachDocument = (body) => {
    return axios
      .post(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.DOC.UPLOAD_DOCUMENTS}`,
        body,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )
      .then((res) => {
        const data = JSON.parse(body?.data);
        getDocumentTree({
          entityType: data?.entity_type,
          entityId: data?.entity_id,
        });
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };

  const deleteAttchedDocument = (body) => {
    return axios
      .delete(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.DOC.MODEL_DOCUMENTS}`,
        {
          params: {
            documentID: body.documentId,
          },
        }
      )
      .then((res) => {
        getDocumentTree({
          entityType: body?.entityType,
          entityId: body?.entityId,
        });
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const downloadReportDocument = (body) => {
    return axios
      .post(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.MI.DOWNLOAD_REPORTS_DOCUMENT}`,
        body,
        {
          responseType: 'arraybuffer',
        }
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const editDocument = (body) => {
    return axios
      .post(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.DOC.REPLACE_DOCUMENT}`,
        body,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )
      .then((res) => {
        const data = JSON.parse(body?.data);
        getDocumentTree({
          entityType: data?.entity_type,
          entityId: data?.entity_id,
        });
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };

  return {
    deleteAttchedDocument,
    attachDocument,
    getDocumentTree,
    fetchDocumentDetails,
    fetchDocumentsList,
    documentTree,
    documentsList,
    setDocumentsList,
    setDocumentTree,
    downloadReportDocument,
    editDocument,
  };
}
