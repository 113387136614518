import { useState } from 'react';
import axios from 'axios';
import { alpha } from '@mui/material/styles';
import API_ENDPOINTS from '../../const/ApiEndPoints';

const BLUE_COLOR_IMPORTANT = '#272D55 !important';
const PRIMARY_CONTRAST_TEXT = '#AECDFC !important';
const ERROR_CONTRAST_TEXT = '#FFFFFF !important';

const defaultTheme = {
  palette: {
    type: 'light',

    primary: {
      main: '#0BB7A7',
      light: '#53B4A7',
      dark: '#0BB7A7',
      contrastText: '#F5FCFB',
    },
    secondary: {
      main: '#97f8ef',
      light: '#c5fbf6',
      dark: '#70f6e9',
      light1: '#E6FFFC',
      light2: '#F1FFFD',
      contrastText: '#FFFFFF',
    },
    success: {
      light: '#52BE7F',
      main: '#27AE60',
      dark: '#1B7943',
      contrastText: '#000000',
    },
    error: {
      main: '#EB5757',
      light: '#EF7878',
      dark: '#A43C3C',
      contrastText: '#FFFFFF',
    },
    warning: {
      main: '#FBAE54',
      light: '#FBBE76',
      dark: '#AF793A',
      contrastText: '#000000',
    },
    info: {
      main: '#FBAE54',
      light: '#FBBE76',
      dark: '#AF793A',
      contrastText: '#000000',
    },
    other: {
      blue1: '#E8F1FF',
      blue2: '#F3F5FF',
      blue3: '#C8DEFF',
      blue4: '#645fe4',
      blue5: '#5890ff',
      brandBlue: '#272D55',
      badgeColor: '#EFCCBE',
      badgeBorder: '#EE883D',
      operationNode: '#EF9A60',
      lightBrandBlueLogin: '#84AFEF',
      white: '#FFFFFF',
      white2: '#ddd',
      black: '#000000',
      grey: '#F5F5F5',
      grey1: '#F4F4F4',
      grey2: '#9396AA',
      gray3: '#E5E8FA',
      gray4: '#E7EAEE',
      gray5: '#f9f9f9',
      purple: '#963DEE',
      yellow: '#B88A14',
      pink: '#B6205F',
      grey3: '#666',
      grey4: '#999',
      pieChart1: '#EBF0B1',
      pieChart2: '#EE9023',
      pieChart3: '#ACB9FD',
      pieChart4: '#BDEDDA',
      pieChart5: '#F4B2B6',
      pieChart6: '#AAD6FF',
      pieChart7: '#F9BCE4',
      pieChart8: '#FFD3B4',
      pieChart9: '#A6DCFB',
      pieChart10: '#B3F0F0',
      pieChart11: '#EBF0B1',
      pieChart12: '#D4BCF2',
      pieChart13: '#FDE5AA',
      pieChart14: '#EDB777',
      barChart1: '#DEF0B8',
      barChart2: '#BDEDDA',
      barChart3: '#B3F0F0',
      barChart4: '#F4B2B6',
      loginSecondaryColor: '#DFE7F3',
      gradient1: 'linear-gradient(90deg, rgba(243,245,255,1) 0%, #F5FCFB 79%)',
      gradient2:
        'linear-gradient(269.82deg, #F5FCFB 57.25%, rgba(200, 222, 255, 0.1) 230.71%)',
      artifactNode: '#0576c7',
      artifactNodeIcon: '#53A7CD',
      modelNode: '#F4B2B6',
      modelPriorityMedium: '#ffff00',
      statusQueued: '#FF9800',
      eventBackgroundColor: '#76a3ec',
      successBackgroundColor: '#EBFBF5',
      errorBackgroundColor: '#FDEDEF',
      successCheckIconBackground: '#35D39D',
      errorCheckIconBackground: '#EF4D61',
      documentNode: '#B78D52',
      attributeNode: '#527EB7',
      mentionArtifact: '#DBF4FF',
      mentionModel: '#FFE4E6',
      mentionAttribute: '#D3E6FF',
      mentionDocument: '#FFE4BF',
      mentionUser: '#DEDEDE',
      decisionNodeBgColor: '#9CA8B3',
      decisionNodeLeftSourceHandleBgColor: '#EF4F0B',
      decisionNodeTargetTopHandleBgColor: '#0182FB',
      decisionNodeRightSourceHandleBgColor: '#F7EA00',
      processingNodeBgColor: '#4CAF50',
      processingNodeTopHandleBgColor: '#0182FB',
      processingNodeBottomHandleBgColor: '#F7EA00',
      terminalNodeBgColor: '#FF5722',
      startNodeBgColor: '#ff562262',
      startNodeBottomHandleBgColor: '#EF4F0B',
      nodesBoxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
      borderColor: 'rgba(233, 233, 233, 1)',
      textEditor: '#E9FFFC',
      mentionModelTextColor: '#B74B52',
      threadTitle: '#2D2D2D',
      threadTagBackground: '#D4FFF9',
    },
    text: {
      primary: '#212936',
      secondary: '#212936',
      light: '#FFFFFF',
      light1: '#EAEAEA',
      disabled: '#56657F',
      dark: '#000000',
      dark1: '#303030',
      dark2: '#DDDDDD',
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFFFFF',
        },
      },
    },

    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: '#fff',
          boxShadow: '0px 6px 12px rgba(132, 175, 239, 0.31)',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: alpha('#c5fbf6', 0.3),
        },
        colorSecondary: {
          borderColor: '#84AFEF',
        },
        filled: {
          '&': {
            backgroundColor: '#53b4a7',

            '& .MuiChip-label': {
              color: '#fff',
            },
          },
          '&:hover': {
            backgroundColor: '#4ba296',
          },
          '& .MuiChip-deleteIcon': {
            color: 'rgb(255 255 255 / 60%)',
          },
          '& .MuiChip-deleteIcon:hover': {
            color: 'rgb(255 255 255 / 50%)',
          },
        },
        label: {
          color: '#272D55',
        },
        deleteIconColorPrimary: {
          color: '#272D55',
          '&:hover': {
            color: '#272D55',
          },
        },
      },
    },

    MuiTableCell: {
      styleOverrides: {
        head: {
          backgroundColor: '#F5FCFB',
          fontWeight: 700,
          color: '#0BB7A7',
          '& .MuiCheckbox-root': {},
          '& .Mui-checked': {},
        },
        root: {
          borderColor: '#FFFFFF',
          background: '#FFFFFF',
        },
        sizeSmall: {
          borderColor: '#F5FCFB',
          '&:hover': {
            backgroundColor: '#FFFFFF',
          },
        },
      },
    },

    MuiTableHead: {
      styleOverrides: {
        root: { borderColor: '#F5FCFB' },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        icon: {
          color: '#53B4A7 !important',
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        barColorPrimary: {
          backgroundColor: '#84AFEF',
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          borderColor: '#FFFFFF',
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        select: {
          background: '#FFFFFF',
          '&:focus-visible': {
            outline: `2px solid #0BB7A7`,
          },
        },
        toolbar: {
          background: '#FFFFFF',
        },
        selectIcon: {
          right: 0,
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        previousNext: {
          background: ERROR_CONTRAST_TEXT,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: alpha('#272D55', 1),
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& .MuiInputLabel-root': {
            color: alpha('#272D55', 0.5),
            '&.Mui-error': {
              color: '#EB5757',
            },
            '& .MuiTypography-root': {
              color: alpha('#272D55', 0.5),
            },
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputLabel-root': {
            color: alpha('#778F9B', 0.5),
          },
          '& .MuiInputLabel-shrink': {
            color: alpha('#778F9B', 0.5),
          },
        },
      },
    },
    MuiBox: {
      styleOverrides: {
        root: {
          backgroundColor: 'none',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          borderColor: '#cef1ed',
        },
        indicator: {
          boxShadow: '0px -2px 14px rgba(39, 45, 85, 0.24)',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: '#70f6e9',
          '&:focus-visible': {
            outline: `2px solid #0BB7A7`,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          '&:focus-visible': {
            outline: '2px solid #008080',
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: '#272D55',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#999',
          '&.Mui-focusVisible': {
            outline: '2px solid #0BB7A7',
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        track: {
          backgroundColor: '#53B4A7 !important',
        },
        thumb: {
          borderColor: '#272D55',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          borderBottomColor: '#A9D9D3',
          '& .MuiListItemButton-root': {
            '& .list-item-button-hover-actions-right': {
              backgroundImage:
                'linear-gradient(269.82deg, #A9D9D368.25%, rgba(200, 222, 255, 0.1) 87.71%)',
            },
            '&:hover': {
              backgroundColor: '#A9D9D3',
            },
          },
        },
      },
    },

    MuiSelect: {
      styleOverrides: {
        select: {
          backgroundColor: '#ffffff ',
        },
        icon: {
          color: BLUE_COLOR_IMPORTANT,
          top: '50%',
          transform: 'translateY(-50%)',
          right: '18px',
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: ERROR_CONTRAST_TEXT,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          boxShadow: `0px 6px 12px ${alpha('#84AFEF', 0.31)}`,
        },
        popper: {
          maxWidth: '600px !important',
          minWidth: '200px !important',
        },
        option: {
          color: '#272D55',
          backgroundColor: ERROR_CONTRAST_TEXT,
          '&[aria-selected="true"]': {
            backgroundColor: '#cef1ed !important',
          },
          '&:focus-visible': {
            backgroundColor: '#E8F1FF !important',
          },
          '&.Mui-focused': {
            backgroundColor: '#dbdbdb !important',
          },
          '&:hover': {
            backgroundColor: '#F4F4F4 !important',
          },
          '&:active': {
            color: '#000000',
            backgroundColor: PRIMARY_CONTRAST_TEXT,
            '&&&:before': {
              backgroundColor: PRIMARY_CONTRAST_TEXT,
            },
            '&&:after': {
              backgroundColor: PRIMARY_CONTRAST_TEXT,
            },
          },
        },
        endAdornment: {
          top: '50%',
          transform: 'translateY(-50%) !important',
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          color: '#272D55',
        },
        track: {
          color: PRIMARY_CONTRAST_TEXT,
        },
        rail: {
          color: PRIMARY_CONTRAST_TEXT,
          opacity: 1,
        },
        valueLabel: {
          color: '#FFFFFF',
          background: '#272D55',
        },
        mark: {
          background: BLUE_COLOR_IMPORTANT,
        },
        markLabel: {
          color: BLUE_COLOR_IMPORTANT,
        },
        thumb: {
          '&:hover': {
            boxShadow: '0px 0px 0px 15px RGBA(174, 205, 252, 0.5) !important',
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: '#F3F5FF',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          background: '#4ba296',
          color: ERROR_CONTRAST_TEXT,
          '& .MuiTypography-root': {
            color: ERROR_CONTRAST_TEXT,
          },
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {},
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: '#0bb7a7',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: '#353935',
        },
      },
    },
  },
};

export default function useThemeAppearance() {
  const [currentSavedTheme, setCurrentSavedTheme] = useState({});
  const getCurrentTheme = () => {
    return axios
      .get(`${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.TA.THEME}`)
      .then((res) => {
        setCurrentSavedTheme(res.data);
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };

  const saveTheme = (body) => {
    return axios
      .put(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.TA.THEME}`,
        body
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };

  return {
    getCurrentTheme,
    currentSavedTheme,
    saveTheme,
    defaultTheme,
    setCurrentSavedTheme,
  };
}
