import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import RootReducer from './RootReducer';

const persistConfig = {
  key: 'persist-storage',
  storage, // by default it will take localstorage
};
const persistedReducer = persistReducer(persistConfig, RootReducer);
const Store = configureStore({
  reducer: persistedReducer,
});
export const persistor = persistStore(Store);
export default Store;
