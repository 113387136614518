// @ts-nocheck
import axios from 'axios';
import API_ENDPOINTS from '../../const/ApiEndPoints';

export default function useAlert() {
  const getAlertEvents = (params) => {
    return axios
      .get(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.MI.ALERT_EVENT}`,

        { params }
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const getAllAlerts = (body) => {
    return axios
      .get(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.MI.GET_ALERTS}`,
        {
          params: body,
        }
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const updateAlerts = (body) => {
    return axios
      .patch(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.MI.GET_ALERTS}`,
        body
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const createAlerts = (body) => {
    return axios
      .post(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.MI.GET_ALERTS}`,
        body
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const deleteAlert = (body) => {
    return axios
      .delete(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.MI.GET_ALERTS}`,
        { params: body }
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  return {
    getAlertEvents,
    createAlerts,
    deleteAlert,
    updateAlerts,
    getAllAlerts,
  };
}
