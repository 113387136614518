// @ts-nocheck
import axios from 'axios';
import API_ENDPOINTS from '../../const/ApiEndPoints';
import useAuth from '../Auth/useAuth';
import useNote from './useNote';
import useEntity from './useEntity';
import useTeam from './useTeam';
import useDocument from './useDocument';
import useAlert from './useAlert';
import useVersion from './useVersion';
import useLog from './useLogs';
import useAttribute from './useAttribute';
import useAssociation from './useAssociation';
import useFilter from './useFilter';
import useSummary from './useSummary';

export default function useModelInventory() {
  const { getAllUsers, getAllRoles } = useAuth();
  const {
    addNote,
    deleteNote,
    editNote,
    getAllNotes,
    getLifecycleNotes,
    editLifecycleNote,
    getNoteReplies,
    searchMentionEntities,
  } = useNote();
  const {
    getAllModelEntity,
    allModelList,
    setAllModelList,
    createModelThirdStep,
    createModelForthStep,
    createModelSecondStep,
    createModelFirstStep,
    deleteModelEntity,
    getSingleEntityDetails,
    updateModelEntity,
    isEntityAvailable,
    integrateEntity,
    getSearchList,
    archiveEntity,
    downloadEntitiesReport,
    getEntityStatuses,
  } = useEntity();
  const { teamDetails, getTeamDetails, addSingleTeamMember, removeTeamMember } =
    useTeam();
  const {
    deleteAttchedDocument,
    attachDocument,
    getDocumentTree,
    fetchDocumentDetails,
    fetchDocumentsList,
    documentTree,
    documentsList,
    downloadReportDocument,
    editDocument,
  } = useDocument();
  const {
    getAlertEvents,
    createAlerts,
    deleteAlert,
    updateAlerts,
    getAllAlerts,
  } = useAlert();
  const { rollbackVersion, getVersionsComparison, getVersions } = useVersion();
  const { getLogActionsAttributes, getLogActions, downloadLogs, getHistory } =
    useLog();
  const {
    getGlobalAttributeValue,
    getLocalAttributeValue,
    getAttributeDetails,
    getFieldsDataTypes,
    getMappedData,
  } = useAttribute();
  const {
    getAssociationDetails,
    removeModelAssociation,
    getTemplateAssociations,
    getParentAssociation,
    associationDetails,
  } = useAssociation();
  const { saveFilter, editFilter, deleteFilter } = useFilter();
  const {
    getStateDetails,
    getShowInSummary,
    getModelPreviousState,
    reclaimState,
    getModelStatus,
    sendForApproval,
    getChartLink,
    getModelLinage,
    getWorkflowCheckList,
    changeWorkflowCheckList,
    getTransitionStates,
    getIssueTypesInfo,
  } = useSummary();
  const getUserRolePermissions = (params) => {
    return axios
      .get(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.MI.GET_USER_ROLE_PERMISSIONS}`,
        { params }
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };

  const getAllAssociations = (params) =>
    axios.get(
      `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.MI.GET_ALL_ASSOCIATIONS}`,
      { params }
    );

  return {
    getAllModelEntity,
    createModelFirstStep,
    createModelSecondStep,
    createModelThirdStep,
    createModelForthStep,
    setAllModelList,
    allModelList,
    getAttributeDetails,
    getTeamDetails,
    teamDetails,
    getAssociationDetails,
    associationDetails,
    addSingleTeamMember,
    getAllUsers,
    deleteModelEntity,
    sendForApproval,
    getAllRoles,
    removeTeamMember,
    removeModelAssociation,
    getSingleEntityDetails,
    fetchDocumentsList,
    documentsList,
    attachDocument,
    deleteAttchedDocument,
    getHistory,
    getAllNotes,
    getNoteReplies,
    addNote,
    saveFilter,
    deleteFilter,
    editFilter,
    getAllAlerts,
    deleteAlert,
    updateAlerts,
    createAlerts,
    updateModelEntity,
    getSearchList,
    getVersions,
    rollbackVersion,
    getVersionsComparison,
    editDocument,
    deleteNote,
    getChartLink,
    editNote,
    getModelStatus,
    getModelLinage,
    getWorkflowCheckList,
    changeWorkflowCheckList,
    getAlertEvents,
    isEntityAvailable,
    getDocumentTree,
    documentTree,
    getFieldsDataTypes,
    integrateEntity,
    getStateDetails,
    getShowInSummary,
    getTemplateAssociations,
    getParentAssociation,
    getIssueTypesInfo,
    getModelPreviousState,
    reclaimState,
    getTransitionStates,
    getLifecycleNotes,
    editLifecycleNote,
    searchMentionEntities,
    fetchDocumentDetails,
    getGlobalAttributeValue,
    getLocalAttributeValue,
    getLogActions,
    getLogActionsAttributes,
    downloadLogs,
    getUserRolePermissions,
    getMappedData,
    downloadReportDocument,
    getAllAssociations,
    archiveEntity,
    downloadEntitiesReport,
    getEntityStatuses,
  };
}
