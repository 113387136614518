// @ts-nocheck
import React, { useCallback, useMemo } from 'react';
import { Grid, Typography, Button, Box, Stack } from '@mui/material';
import { useNavigate, useRouteError } from 'react-router-dom';

import { ReactComponent as NimbusLogo } from '../../assets/Icons/MRMVaultColored.svg';
import { HAS_REFRESHED_KEY } from '../../const/CommonConst';

const ErrorBoundary = () => {
  const error = useRouteError();
  const navigate = useNavigate();

  const showChunkLoadError = useMemo(() => {
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem(HAS_REFRESHED_KEY) || 'false'
    );
    return error?.message?.includes('ChunkLoadError') && !hasRefreshed;
  }, [error]);

  const unselectableStyles = {
    userSelect: 'none',
    WebkitTouchCallout: 'none',
    KhtmlUserSelect: 'none',
    MozUserSelect: 'none',
    msUserSelect: 'none',
  };
  const handleReload = useCallback(() => {
    window.location.reload();
  }, []);

  const handleGoToHome = useCallback(() => {
    navigate('/');
  }, [navigate]);

  const getErrorTitle = () => {
    if (showChunkLoadError) {
      return 'New version detected';
    }
    return 'Something went wrong';
  };

  const getErrorMessage = () => {
    if (showChunkLoadError) {
      return 'Refreshing the page in 3 seconds...';
    }
    return error?.message || 'Please try again later';
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{ height: '100vh', flexDirection: 'column' }}
    >
      <Grid item>
        <Box p={1} marginBottom={2}>
          <NimbusLogo height="100px" width="170px" />
        </Box>
      </Grid>
      <Grid item sx={{ textAlign: 'center' }}>
        <Typography
          variant="h5"
          color="primary"
          fontWeight="bold"
          sx={{ fontSize: 20, ...unselectableStyles }}
        >
          {getErrorTitle()}
        </Typography>
        <Typography>{getErrorMessage()}</Typography>
      </Grid>
      <Grid item mt={5}>
        <Stack gap={2} direction="row">
          <Button variant="outlined" onClick={handleGoToHome}>
            Home
          </Button>
          <Button variant="contained" color="primary" onClick={handleReload}>
            Reload
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default ErrorBoundary;
