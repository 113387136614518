// @ts-nocheck
import axios from 'axios';
import API_ENDPOINTS from '../../const/ApiEndPoints';

export default function useFilter() {
  const saveFilter = (body) =>
    axios
      .post(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.MI.SAVE_FILTER}`,
        body
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });

  const deleteFilter = (body) => {
    return axios
      .delete(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.MI.DELETE_FILTER}`,
        { params: body }
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const editFilter = (body) => {
    return axios
      .put(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.MI.DELETE_FILTER}`,
        body
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };

  return { saveFilter, editFilter, deleteFilter };
}
