import axios from 'axios';
import API_ENDPOINTS from '../../const/ApiEndPoints';

export default function useAccountId() {
  const validateAccount = (params) => {
    return axios
      .get(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.AUTH.VALIDATE_ACCOUNT}`,
        {
          params,
        }
      )
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        throw error;
      });
  };
  return {
    validateAccount,
  };
}
