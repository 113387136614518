// @ts-nocheck
import axios from 'axios';
import API_ENDPOINTS from '../../const/ApiEndPoints';

export default function useNote() {
  const addNote = (body) => {
    return axios
      .post(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.MI.NOTES}`,
        body
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const editNote = (body) => {
    return axios
      .put(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.MI.NOTES}`,
        body
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const deleteNote = (body) => {
    return axios
      .delete(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.MI.NOTES}`,
        {
          params: body,
        }
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const getAllNotes = (body) => {
    return axios
      .get(`${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.MI.NOTES}`, {
        params: body,
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const getLifecycleNotes = (body) => {
    return axios
      .get(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.MI.GET_LIFECYCLE_NOTES}`,
        {
          params: body,
        }
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const editLifecycleNote = (body) => {
    return axios
      .put(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.MI.GET_LIFECYCLE_NOTES}`,
        body
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const searchMentionEntities = (body) => {
    return axios
      .get(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.MI.SEARCH_MODELS}`,
        {
          params: body,
          loader: false,
        }
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const getNoteReplies = (body) => {
    return axios
      .get(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.MI.GET_REPLIES}`,
        {
          params: body,
        }
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  return {
    addNote,
    deleteNote,
    editNote,
    getAllNotes,
    getLifecycleNotes,
    editLifecycleNote,
    searchMentionEntities,
    getNoteReplies,
  };
}
